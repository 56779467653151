import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PaletteIcon: FC<SVGIconProps> = ({ className, ...rest }) => (
  <svg
    className={classNames("fill-current", className)}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    fill="none"
    viewBox="0 0 24 25"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4.5C2 3.94772 2.44772 3.5 3 3.5H7C7.55228 3.5 8 3.94772 8 4.5V11.3L12.7929 6.50709C13.1834 6.11657 13.8166 6.11657 14.2071 6.50709L17.0355 9.33552C17.4261 9.72605 17.4261 10.3592 17.0355 10.7497L12.2853 15.5H19C19.5523 15.5 20 15.9477 20 16.5V20.5C20 21.0523 19.5523 21.5 19 21.5H5C3.34315 21.5 2 20.1569 2 18.5V4.5ZM9.45684 15.5H8V14.1284L13.1464 8.98197C13.3417 8.78671 13.6583 8.78671 13.8536 8.98197L14.5607 9.68907C14.7559 9.88434 14.7559 10.2009 14.5607 10.3962L9.45684 15.5ZM7.82929 19.5H17.5C17.7761 19.5 18 19.2761 18 19V18C18 17.7239 17.7761 17.5 17.5 17.5H8V18.5C8 18.8506 7.93985 19.1872 7.82929 19.5ZM6 6C6 5.72386 5.77614 5.5 5.5 5.5H4.5C4.22386 5.5 4 5.72386 4 6V18.5C4 19.0523 4.44772 19.5 5 19.5C5.55228 19.5 6 19.0523 6 18.5V6Z"
    />
  </svg>
);

export default PaletteIcon;
