import { FC, useEffect, useState } from "react";

import { Button } from "@components/Button";
import CloseIcon from "@components/Icons/CloseIcon";
import LoadingSpinner from "@components/LoadingSpinner";
import Modal from "@components/Modals/Modal";

export interface VideoModalProps {
  show: boolean;
  toggleShow: () => void;
  videoUrl?: string;
  autoplay?: boolean;
}

const VideoModal: FC<VideoModalProps> = ({
  show,
  toggleShow,
  videoUrl,
  autoplay = false,
}) => {
  const [url, setUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    const youtubeUrlRegex =
      /(https?:\/\/)?(((m|www)\.)?(youtube)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
    const youtubeVideoId = videoUrl?.match(youtubeUrlRegex)?.[7];

    if (youtubeVideoId) {
      // Use correct URL format for embedding if YouTube video
      setUrl(`https://www.youtube.com/embed/${youtubeVideoId}`);
    } else {
      setUrl(videoUrl);
    }
  }, [videoUrl]);

  return (
    <Modal
      className="relative"
      show={show}
      toggleShow={toggleShow}
      size="full"
      withoutContainer
      darkerOverlay
    >
      <div className="relative w-full max-w-[960px] m-auto">
        <div className="relative h-0 pb-[56.5%]">
          {url ? (
            <iframe
              className="absolute top-0 left-0 w-full h-full rounded-lg overflow-hidden"
              src={`${url}?autoplay=${
                autoplay ? "1" : "0"
              }&modestbranding=1&playsinline=0&rel=0`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          ) : (
            <LoadingSpinner className="m-auto" variant="transparent" />
          )}
        </div>
      </div>
      <div className="flex absolute inset-0 w-full pointer-events-none">
        <div className="w-full max-w-[1080px] m-auto">
          <div className="relative h-0 pb-[62%]">
            <Button
              className="absolute top-0 right-0 pointer-events-auto"
              variant="white"
              square
              circle
              onClick={toggleShow}
            >
              <CloseIcon />
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;
