import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 7C8.06498 7 6.73858 7.44026 5.66326 8.1859C4.09031 9.18174 3 10.8808 3 13.1538C3 20.8461 15.1875 28 16 28C16.8125 28 29 20.8462 29 13.1539C29 10.8808 27.9097 9.1817 26.3367 8.18585C25.2614 7.44024 23.935 7 22.5 7C19.9351 7 17.7172 8.40646 16.6598 10.4492C16.4184 10.8068 16.1972 11.1953 16 11.6154C15.8028 11.1953 15.5816 10.8068 15.3402 10.4492C14.2828 8.40647 12.0649 7 9.5 7Z"
        fill="#E46767"
      />
      <path d="M26 8L26 2L20 12H24L24 18L30 8L26 8Z" fill="#F1C27D" />
    </svg>
  );
};

export default Icon;
