import React, { FC, useCallback, useEffect, useState } from "react";
import {
  ActivityAction,
  ActivityApi,
  ActorType,
  ResourceType,
} from "@practice/sdk";
import {
  getStripeSubscriptionPlans,
  getStripeSubscriptionUpdatePreview,
  updateStripeSubscription,
} from "api-services/definitions/stripe";
import { useApi, useApiGet } from "api-services/endpoints";
import classNames from "classnames";
import { capitalize } from "lodash";
import { useSWRConfig } from "swr";

import { useAuth } from "@contexts/auth";
import usePersistentState from "@hooks/use-persistent-state";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import useSnackbar from "@hooks/use-snackbar";
import useStripeInfo from "@hooks/use-stripe-info";
import { SubscriptionPlanType } from "@lib/data/schemas/subscription-plan";
import { displayItemAmountString } from "@lib/products";
import {
  isDowngradeSubscriptionPlan,
  isFrequencyChange,
  track,
  translateToSubscriptionPlan,
} from "@lib/utils/subscription-plans";

import { TodaysCharge } from "@components/DisplayDetails/ChargeInfo";
import NewStatusIcon from "@components/Icons/NewStatusIcon";
import LoadingSpinner from "@components/LoadingSpinner";
import BigModal from "@components/Modals/BigModal";
import SmallModal from "@components/Modals/SmallModal";
import TiersWelcomeModal from "@components/Modals/TiersWelcomeModal";

import { SubscriptionPlanDowngradeModal } from "../Alerts";
import SelectPlanContent from "../SelectPlanContent";
import { SubscriptionPlanModalProps } from "../types";

const FREQUENCY_TO_TEXT = {
  month: "Monthly",
  year: "Yearly",
  lifetime: "Lifetime",
};

export const INTERVAL_OPTIONS = Object.entries(FREQUENCY_TO_TEXT).map(
  ([value, label]) => ({ value, label })
);

const Line: React.FC<{
  value: number;
  currency: string;
  description: string;
  className?: string;
}> = ({ currency, description, value, className }) => {
  return (
    <div
      className={classNames(
        "flex space-between items-center text-sm h-12",
        className
      )}
    >
      <div className="mr-4">{description}</div>
      <div className="ml-auto">{displayItemAmountString(value, currency)}</div>
    </div>
  );
};

export const SubscriptionUpdatePreview: FC<{ previewPriceId: string }> = ({
  previewPriceId,
}) => {
  const { oid } = useAuth();
  const { subscription } = useStripeInfo(oid);

  const { data } = useApiGet(
    getStripeSubscriptionUpdatePreview,
    { subscriptionId: subscription?.id, userId: oid },
    { previewPriceId }
  );

  if (!data) return <LoadingSpinner className="m-auto" />;

  const { currency, preview, total, current, credits, creditsUsed } = data;

  return (
    <div>
      <div className="p-4 divide-y divide-grey-900 rounded-xl bg-grey-950 mb-4">
        <Line
          currency={currency}
          description={preview.description}
          value={preview.amount}
        />
        {current && (
          <Line
            className="text-grey-500"
            currency={currency}
            description={current.description}
            value={current.amount}
          />
        )}
        {creditsUsed && (
          <Line
            className="text-grey-500"
            currency={currency}
            description={creditsUsed.description}
            value={creditsUsed.amount}
          />
        )}
        <Line
          className="text-lg font-medium pt-3 "
          currency={currency}
          description="Total due"
          value={total}
        />
      </div>
      <TodaysCharge
        total={displayItemAmountString(total, currency)!}
        isTrial={preview.isTrial}
      />
      {credits ? (
        <div className="mt-4 h-14 px-4 text-blue-300 items-center bg-blue-950 rounded-lg flex">
          <NewStatusIcon />
          <span className="ml-2">Credits Available</span>
          <div className="ml-auto">
            {displayItemAmountString(credits, currency)}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const getPlan = (plan: SubscriptionPlanType) => {
  return `${FREQUENCY_TO_TEXT[
    plan.frequency
  ]?.toLocaleLowerCase()} ${capitalize(plan.tier)}`;
};

export const getTitle = (
  current: SubscriptionPlanType,
  preview: SubscriptionPlanType
) => {
  if (current.frequency === preview.frequency) {
    return `${
      isDowngradeSubscriptionPlan(current, preview)
        ? "Downgrading"
        : "Upgrading"
    } to ${getPlan(preview)} plan`;
  }

  return `Switching from ${getPlan(current)} to ${getPlan(preview)} plan`;
};

export const useGetCurrentPlan = () => {
  const { oid } = useAuth();
  const { subscription } = useStripeInfo(oid);

  const plan = subscription?.items?.data[0].plan;

  return plan ? translateToSubscriptionPlan(plan) : undefined;
};

const SubscriptionPlanModal: FC<SubscriptionPlanModalProps> = ({
  show,
  toggleShow,
  onClose,
  onSuccess,
}) => {
  const { oid, organization } = useAuth();
  const { subscription } = useStripeInfo(oid);
  const snackbar = useSnackbar();
  const currentPlan = useGetCurrentPlan();
  // @TODO: after integrate the price calculation, we should
  //        build the `label` using the `Tag` component to display the
  //        discount percentage

  const [previewPlan, setPreview] = useState<SubscriptionPlanType>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState(false);

  const { persistentSetValue: setSeenProModal } =
    usePersistentState<Date | null>("seenProModal", null);

  const { data: subscriptionPlans, loading: isLoading } = useApiGet(
    getStripeSubscriptionPlans,
    {},
    {}
  );

  const { apiCall: update, loading, error } = useApi(updateStripeSubscription);
  const activityApi = useSDKApi(ActivityApi);
  const generateRequestId = useRequestIdGenerator("SubscriptionPlanModal");

  useEffect(() => {
    if (currentPlan) {
      track("coach_views_subscription_plan_select_modal", currentPlan);
    }
  }, []);

  useEffect(() => {
    if (previewPlan && currentPlan) {
      track("coach_views_subscription_confirmation_modal", previewPlan);
    }
  }, [previewPlan, currentPlan]);

  useEffect(() => {
    if (error) {
      handleCloseAll();
      snackbar.showWarning(
        "Unable to change the plan",
        "Check if you have a valid credit card attached"
      );
    }
  }, [error]);

  const handleChange = (value: SubscriptionPlanType) => {
    setPreview(value);
  };

  const handleOpenDowngradeModal = () => {
    setShowDowngradeModal(true);
    track("coach_subscription_plan_open_downgrade_modal", previewPlan);
  };

  const { mutate } = useSWRConfig();

  const handleCloseAll = () => {
    setShowSuccessModal(false);
    setPreview(undefined);
    setShowDowngradeModal(false);
    toggleShow && toggleShow(false);
  };

  const registerActivityLog = () => {
    const accountIds =
      organization?.memberAccountIds?.filter(
        (accountId) => accountId !== oid
      ) || [];

    for (const accountId of accountIds) {
      activityApi.publishActivityMessage({
        publishActivityMessageRequest: {
          activityMessage: {
            action: ActivityAction.Unrelate,
            actorType: ActorType.Account,
            actorId: accountId,
            resourceType: ResourceType.Account,
            resourceId: accountId,
            organizationId: oid,
          },
        },
        xRequestId: generateRequestId(),
      });
    }
  };

  const updateToPreview = useCallback(async () => {
    if (!previewPlan || !currentPlan || !oid || !subscription?.id) return;

    await update(
      { userId: oid, subscriptionId: subscription?.id },
      {},
      { newPriceId: previewPlan.priceId }
    );

    await mutate(
      (key: string) =>
        key.startsWith?.(`/api/v1/organizations/${oid}/authorization/`)
    );

    await mutate(
      (key: string) => key.startsWith?.(`/api/v1/users/${oid}/stripe/`)
    );

    if (
      isDowngradeSubscriptionPlan(currentPlan, previewPlan) ||
      isFrequencyChange(currentPlan, previewPlan)
    ) {
      registerActivityLog();
      handleCloseAll();
    } else {
      setShowSuccessModal(true);
    }
    track("coach_subscription_plan_updated", previewPlan);
  }, [update, oid, subscription?.id, previewPlan]);

  // reloading phase
  if (showSuccessModal && !currentPlan) return null;

  if (
    showSuccessModal &&
    currentPlan &&
    previewPlan &&
    previewPlan.tier !== "basic" &&
    !error &&
    !isFrequencyChange(currentPlan, previewPlan) &&
    !isDowngradeSubscriptionPlan(currentPlan, previewPlan)
  ) {
    return (
      <TiersWelcomeModal
        variant={previewPlan.tier}
        onDismiss={() => {
          setSeenProModal(new Date());
          onSuccess?.(previewPlan.tier);
          handleCloseAll();
        }}
      />
    );
  }

  if (showDowngradeModal) {
    return (
      <SubscriptionPlanDowngradeModal
        show={true}
        toggleShow={() => {
          handleCloseAll();
        }}
        onChange={updateToPreview}
        isLoading={loading}
      />
    );
  }

  if (previewPlan && currentPlan) {
    return (
      <SmallModal
        show={true}
        toggleShow={() => setPreview(undefined)}
        onAction={
          isDowngradeSubscriptionPlan(currentPlan, previewPlan)
            ? handleOpenDowngradeModal
            : updateToPreview
        }
        title={getTitle(currentPlan, previewPlan)}
        onActionText="Confirm"
        onActionLoading={loading}
        heapModalName="coach_subscription_plan_confirm_update_modal"
        isCloseAfterPrimaryActionDisabled
      >
        <SubscriptionUpdatePreview previewPriceId={previewPlan.priceId} />
      </SmallModal>
    );
  }

  return (
    <BigModal
      size="biggest"
      show={show}
      toggleShow={toggleShow}
      title="Select a plan"
      description="Select a new plan and your prefered billing cycle"
      isLoading={isLoading}
      contentClassName="max-h-[650px]"
    >
      <div className="">
        {!isLoading && (
          <SelectPlanContent
            currentPlan={currentPlan}
            subscriptionPlans={subscriptionPlans || []}
            intervalOptions={INTERVAL_OPTIONS}
            onChange={handleChange}
            onKeep={onClose}
          />
        )}
      </div>
    </BigModal>
  );
};

export default SubscriptionPlanModal;
