import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const AssignIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M4.831 8.445a8 8 0 110 7.11 1 1 0 10-1.79.89A10 10 0 0012 22c5.522 0 10-4.477 10-10S17.522 2 12 2a10 10 0 00-8.96 5.555 1 1 0 001.79.89zm10.223 3.199a.5.5 0 010 .707l-4.243 4.242a.5.5 0 01-.707 0l-.707-.707a.5.5 0 010-.707l2.182-2.182-8.079.007c-.276 0-.5-.23-.5-.507v-1c0-.276.217-.497.493-.497l8.086-.003-2.182-2.182a.5.5 0 010-.707l.707-.707a.5.5 0 01.707 0l4.243 4.243z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default AssignIcon;
