import React, { FC } from "react";
import BrushIcon from "components/Icons/BrushIcon";
import ClientRemoveIcon from "components/Icons/ClientRemoveIcon";
import LinkedIcon from "components/Icons/LinkedIcon";
import PenIcon from "components/Icons/PenIcon";
import SmileIcon from "components/Icons/SmileOffIcon";
import SocialWebIcon from "components/Icons/SocialWebIcon";
import SmallModal from "components/Modals/SmallModal";

import {
  SubscriptionPlanDowngradeModalDataType,
  SubscriptionPlanDowngradeModalProps,
} from "../types";

const data: SubscriptionPlanDowngradeModalDataType[] = [
  {
    icon: PenIcon,
    text: "Forms with digital signatures will be inactive",
  },
  {
    icon: SocialWebIcon,
    text: "All pages will return to a Practice domain",
  },
  {
    icon: LinkedIcon,
    text: "Zapier integrations will not work",
  },
  {
    icon: BrushIcon,
    text: "Remove your brand in client experience",
  },
  {
    icon: ClientRemoveIcon,
    text: "Team members will be removed",
  },
];

const SubscriptionPlanDowngradeModal: FC<
  SubscriptionPlanDowngradeModalProps
> = ({ show, toggleShow, isLoading = false, onChange }) => {
  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      title="Are you sure?"
      description="Downgrading will result in the following:"
      icon={SmileIcon}
      iconColor="action"
      heapModalName="coach_subscription_plan_downgrade_modal"
      onActionText="Keep Pro plan"
      onAction={toggleShow}
      onSecondaryActionText="Downgrade to basic plan"
      onSecondaryAction={onChange}
      onSecondaryActionLoading={isLoading}
      showCancel={false}
      isCloseAfterSecondaryActionDisabled
    >
      <ul className="mb-8">
        {data.map(({ icon: Icon, text }, index) => (
          <li
            key={`downgrade-item-${index}`}
            className="flex text-sm mb-4 items-center"
          >
            <Icon className="mr-1" />
            {text}
          </li>
        ))}
      </ul>
    </SmallModal>
  );
};

export default SubscriptionPlanDowngradeModal;
