import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const ClientRemoveIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M11 13a5 5 0 100-10 5 5 0 000 10zm0-2a3 3 0 100-6 3 3 0 000 6zm-6 7a2 2 0 012-2h6.256a4.5 4.5 0 101.415-2H7a4 4 0 00-4 4v1.5a.5.5 0 00.5.5h1a.5.5 0 00.5-.5V18zm10.556-2.091a.25.25 0 000 .354l1.237 1.237-1.237 1.237a.25.25 0 000 .354l.353.354a.25.25 0 00.354 0l1.237-1.238 1.237 1.238a.25.25 0 00.354 0l.354-.354a.25.25 0 000-.354L18.207 17.5l1.238-1.237a.25.25 0 000-.354l-.354-.354a.25.25 0 00-.354 0L17.5 16.794l-1.237-1.237a.25.25 0 00-.354 0l-.354.353z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ClientRemoveIcon;
