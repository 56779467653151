import React from "react";

const LargeTextAreaForm = ({ content, onChange, placeholder }) => {
  return (
    <div>
      <textarea
        placeholder={placeholder ? placeholder : null}
        onChange={onChange}
        value={content}
        id="first_name"
        className="mt-1 form-input block w-full h-64 py-2 px-3 border border-grey-900 rounded-md hover:bg-grey-950 hover:border-grey-950 focus:outline-none focus:shadow-none focus:bg-white focus:border-action-700 transition duration-150 ease-in-out focus:ring-0"
      />
    </div>
  );
};

export default LargeTextAreaForm;
