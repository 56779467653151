import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PlayIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_996_1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.75006 16.7631L17.2501 12.433C17.5834 12.2406 17.5834 11.7594 17.2501 11.567L9.75006 7.23686C9.41673 7.04441 9.00006 7.28497 9.00006 7.66987V16.3301C9.00006 16.715 9.41673 16.9556 9.75006 16.7631Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_996_1">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlayIcon;
