import { ChangeEvent, FC, ReactNode } from "react";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import classNames from "classnames";
import { compact } from "lodash";

import TextFieldForm from "@components/Form/TextFieldForm";

type ThemeTypes = "light" | "dark";
type StylePropertyTypes = "selected" | "normal";

export type StyleType = {
  [key in ThemeTypes]: {
    [key in StylePropertyTypes]: string;
  };
};

const styles: StyleType = {
  light: {
    selected:
      "disabled:bg-grey-950 disabled:border-grey-900 text-black-ink bg-action-700 hover:bg-action-600 active:bg-action-500 border-black-ink/25",
    normal:
      "text-black-ink bg-white disabled:hover:bg-white hover:bg-grey-900 active:bg-action-700 border-grey-900",
  },
  dark: {
    selected:
      "text-white bg-action-500 hover:bg-action-400 active:bg-action-300 border-black-ink/25",
    normal:
      "text-grey-900 bg-grey-150 hover:bg-grey-250 active:bg-grey-300 border-grey-250",
  },
};

export interface RadioOptionType<T = number | string> {
  label: ReactNode;
  value: T;
}

export interface RadioProps<T = number | string> {
  className?: string;
  theme?: ThemeTypes;
  name?: string;
  label?: string;
  customHelper?: string;
  withCustom?: boolean;
  options: RadioOptionType<T>[];
  value: T;
  onChange: (value: T) => void;
  register?: UseFormRegister<any>;
  errors?: FieldErrors<any>;
  customValidation?: (value: T) => string | undefined;
  disabled?: boolean;
}

const Radio: FC<RadioProps> = ({
  className,
  theme = "light",
  name,
  label,
  customHelper,
  options,
  value,
  onChange,
  withCustom = false,
  register,
  errors,
  customValidation,
  disabled = false,
}) => {
  const style = styles[theme] || {};
  const lowerCaseLabel = label?.toLowerCase();
  const formattedOptions = compact([
    ...(options || []),
    withCustom && { label: "custom", value: "custom" },
  ]);
  const optionValues = formattedOptions.map((item) => item.value);

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className="block text-sm leading-5 text-grey-500 mb-1"
      >
        {label}
      </label>
      <div
        className={`relative z-0 rounded-md grid grid-cols-${formattedOptions.length}`}
      >
        {!!formattedOptions?.length &&
          formattedOptions.map((option, index, array) => {
            const isSelected =
              option.value === "custom"
                ? !optionValues.includes(value)
                : value == option.value;
            return (
              <button
                key={`radio-option-item-${option.value}`}
                type="button"
                onClick={() => onChange(option.value)}
                disabled={disabled}
                className={classNames(
                  "relative capitalize inline-flex items-center justify-center border leading-6 focus:z-10 focus:outline-none transition ease-in-out option-150 px-4",
                  theme == "dark" ? "py-3" : "py-2",
                  isSelected ? style.selected : style.normal,
                  index === 0 ? "rounded-l-md" : "-ml-px",
                  index === array.length - 1 && "rounded-r-md"
                )}
              >
                {option.label}
              </button>
            );
          })}
      </div>
      {withCustom &&
        (value === "custom" || !value || !optionValues.includes(value)) && (
          <TextFieldForm
            register={register}
            errors={errors}
            name={name as string}
            containerClassName="mt-6"
            label={label && `Custom ${lowerCaseLabel}`}
            type="number"
            errorMessage={label && `Set a custom ${lowerCaseLabel}`}
            defaultValue={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onChange(parseInt(e.target.value))
            }
            helperClassName={disabled ? "bg-grey-950 text-grey-500" : ""}
            helper={customHelper}
            required
            customValidation={customValidation}
            disabled={disabled}
          />
        )}
    </div>
  );
};

export default Radio;
