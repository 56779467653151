import {
  LearnMoreItemCategoryType,
  VideoItemCategoryType,
} from "api-services/definitions/webflow";

export const collectionIds = {
  learnMore: "63ce9b4270e278f5324aac1f",
  videos: "642593daacbc7c31876b1b5e",
};

export const videoIds = {
  welcomeModal: "644be91747efe0498b21dd7b",
  proModal: "644826ff5184539d2d6edaf2",
  teamsModal: "64dd187293f80b411c65e893",
  clients: "642597ee5c3895b0107f7b64",
  packages: "6425976f1a45cac7b118b2f8",
  library: "644825a4dc691e1d4106edc1",
  schedulers: "644825fdfaae6eaed468f9db",
  forms: "644825d2dc691e089006f203",
};

interface CategoryInfo {
  title: string;
  description: string;
}

export const learnMoreCategories: Record<
  LearnMoreItemCategoryType,
  CategoryInfo
> = {
  appointments: {
    title: "About appointments",
    description:
      "The simplest way to coordinate client scheduling and appointments.",
  },
  forms: {
    title: "About forms",
    description:
      "Use forms for client intake, info capture and progress tracking.",
  },
  services: {
    title: "About services",
    description: "Create products, packages and easily sell your services.",
  },
  library: {
    title: "About library",
    description:
      "Organize all of your coaching resources with unlimited storage.",
  },
  contacts: {
    title: "About contacts",
    description: "A consolidated way to keep important client information.",
  },
  inbox: {
    title: "About inbox",
    description: "All of your client emails in one consolidated place.",
  },
  "account-management": {
    title: "About account management",
    description: "Manage your subscription and billing information.",
  },
  profile: {
    title: "About custom domains",
    description: "Customize your Practice app domain",
  },
};

export const videoCategories: Record<VideoItemCategoryType, CategoryInfo> = {
  "get-started": {
    title: "Get started videos",
    description:
      "Learn how to get started in less than 10-minutes with our how-to videos below.",
  },
};
