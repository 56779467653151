import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PenIcon: FC<SVGIconProps> = ({ className, ...rest }) => (
  <svg
    className={classNames("fill-current", className)}
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    fill="none"
    viewBox="0 0 26 24"
    {...rest}
  >
    <g clipPath="url(#clip0_642_25463)">
      <path
        fillRule="evenodd"
        d="M12.789 3.786a1.072 1.072 0 011.465-.129l.806.648.027.022 6.371 5.12.806.648a.968.968 0 01.123 1.404c-.37.423-1.027.48-1.465.128l-.588-.473c-.638 1.256-1.113 2.986-.023 4.414.244.319.139.819-.255.937l-13.1 3.927c-.363.109-.722-.18-.673-.542L8.046 6.853c.053-.392.55-.578.92-.403 1.657.78 3.353.032 4.534-.788l-.588-.472a.967.967 0 01-.123-1.404zm2.35 3.194l3.555 2.856c-.16.283-.322.595-.47.93-.467 1.064-.93 2.68-.446 4.326l-7.261 2.177 3.856-4.402a.95.95 0 00-.12-1.378l-.041-.033a1.053 1.053 0 00-1.439.126l-3.856 4.401.978-7.227c1.777.17 3.358-.546 4.37-1.172.32-.197.612-.403.875-.604z"
        clipRule="evenodd"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_642_25463">
        <path
          fill="#fff"
          d="M0 0H25.059V24H0z"
          transform="translate(.706)"
        ></path>
      </clipPath>
    </defs>
  </svg>
);

export default PenIcon;
