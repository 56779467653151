import React, { FC, useEffect, useState } from "react";
import axios from "axios";
import CheckIcon from "components/Icons/CheckIcon";
import SmileIcon from "components/Icons/SmileOffIcon";
import SmallModal from "components/Modals/SmallModal";

import { useAuth } from "@contexts/auth";
import { track } from "@lib/utils/subscription-plans";

import {
  SubscriptionPlanCancelContentDataItemType,
  SubscriptionPlanCancelContentDataType,
  SubscriptionPlanCancelModalProps,
  SubscriptionPlanCancelStepTypes,
} from "../types";

const contentDateRequest: SubscriptionPlanCancelContentDataItemType = {
  title: "Want to cancel your account?",
  description:
    "We are sorry to see you go. Please note that you will have access to your account until the end of your subscription period.",
  icon: SmileIcon,
  iconColor: "peach",
};

const contentData: SubscriptionPlanCancelContentDataType = {
  request: contentDateRequest,
  "request-with-credits": {
    ...contentDateRequest,
    description:
      "We are sorry to see you go. Please note that unused credits will not be refunded but will remain in your account when you should want to use the product again.",
  },
  success: {
    title: "Cancellation request sent",
    description:
      "Your cancellation request is confirmed. Our team will reach out within the next 24 hours.",
    icon: CheckIcon,
    iconColor: "green",
  },
};

const SubscriptionPlanCancelModal: FC<SubscriptionPlanCancelModalProps> = ({
  show,
  toggleShow,
  subscriptionPlan,
}) => {
  const { uid, customer } = useAuth();
  const [step, setStep] = useState<SubscriptionPlanCancelStepTypes>(
    customer.balance < 0 ? "request-with-credits" : "request"
  );
  const [loading, setLoading] = useState(false);
  const isRequestStep = ["request", "request-with-credits"].includes(step);
  const data = contentData[step];

  useEffect(() => {
    track("coach_opens_subscription_plan_cancel_modal", subscriptionPlan);
  }, []);

  const sendCancelMessage = async () => {
    setLoading(true);
    await axios.post("/api/v1/feedback", {
      userId: uid,
      content: "I would like to cancel this subscription",
    });
    track("coach_requests_to_cancel_subscription_plan", subscriptionPlan);
    setLoading(false);
    setStep("success");
  };

  return (
    <SmallModal
      show={show}
      toggleShow={toggleShow}
      title={data.title}
      description={data.description}
      icon={data.icon}
      iconColor={data.iconColor}
      onActionText={isRequestStep ? "Request cancellation" : undefined}
      onAction={isRequestStep ? sendCancelMessage : undefined}
      onActionLoading={loading}
      isCloseAfterPrimaryActionDisabled={true}
      heapModalName="coach_subscription_plan_cancel_modal"
    />
  );
};

export default SubscriptionPlanCancelModal;
