import { useContext } from "react";

import { FeedbackModalContext } from "@contexts/feedback";

const useFeedbackModal = () => {
  const { showFeedbackModal, setShowFeedbackModal } =
    useContext(FeedbackModalContext);

  return [showFeedbackModal, setShowFeedbackModal];
};

export default useFeedbackModal;
