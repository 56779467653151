import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LinkedIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5564 6.60701L17.1422 5.1928C16.7517 4.80227 16.1185 4.80227 15.728 5.1928L13.3922 7.52859C13.1969 7.72385 12.8803 7.72385 12.6851 7.52859L11.978 6.82148C11.7827 6.62622 11.7827 6.30964 11.978 6.11438L14.3138 3.77858C15.4853 2.60701 17.3848 2.60701 18.5564 3.77858L19.9706 5.1928C21.1422 6.36437 21.1422 8.26386 19.9706 9.43544L14.3138 15.0923C13.1422 16.2639 11.2427 16.2639 10.0711 15.0923L9.42607 14.5099C9.21369 14.3181 9.20526 13.9875 9.4076 13.7852L10.1138 13.079C10.3021 12.8908 10.6048 12.883 10.8024 13.0615L11.4853 13.6781C11.8758 14.0686 12.509 14.0686 12.8995 13.6781L18.5564 8.02122C18.9469 7.6307 18.9469 6.99753 18.5564 6.60701ZM13.1823 11.2826C13.3757 11.4877 13.7003 11.4925 13.8997 11.2931L14.6065 10.5863C14.7977 10.3951 14.8022 10.0865 14.6167 9.88979L13.7782 9.00041C12.6066 7.82884 10.7071 7.82884 9.53553 9.00041L3.87868 14.6573C2.70711 15.8288 2.70711 17.7283 3.87868 18.8999L5.29289 20.3141C6.46447 21.4857 8.36396 21.4857 9.53553 20.3141L11.9994 17.9249C12.1998 17.7306 12.2023 17.4098 12.0049 17.2124L11.2979 16.5054C11.1048 16.3123 10.7924 16.3099 10.5963 16.5L8.12132 18.8999C7.7308 19.2904 7.09763 19.2904 6.70711 18.8999L5.29289 17.4857C4.90237 17.0952 4.90237 16.462 5.29289 16.0715L10.9497 10.4146C11.3403 10.0241 11.9734 10.0241 12.364 10.4146L13.1823 11.2826Z"
      />
    </svg>
  );
};

export default LinkedIcon;
