import React, { FC } from "react";

import { SVGIconProps } from "@lib/shared-types";

const NewStatusIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_6418_2864)">
        <circle cx="12" cy="12" r="12" fill="#BFCCE2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6666 6.65497C12.6601 6.28912 12.36 5.99682 11.9942 6.00003C11.6283 6.00324 11.3333 6.30076 11.3333 6.66667L11.3339 6.73591C11.3336 7.83636 10.7666 8.99023 9.85743 9.88372C8.94832 10.7771 7.77473 11.3333 6.66667 11.3333C6.29848 11.3333 6 11.6318 6 12C6 12.3682 6.29848 12.6667 6.66667 12.6667C7.78318 12.6667 8.95549 13.2316 9.86193 14.1381C10.7684 15.0445 11.3333 16.2168 11.3333 17.3333C11.3333 17.7015 11.6318 18 12 18C12.3682 18 12.6667 17.7015 12.6667 17.3333C12.6667 16.2168 13.2316 15.0445 14.1381 14.1381C15.0445 13.2316 16.2168 12.6667 17.3333 12.6667C17.7015 12.6667 18 12.3682 18 12C18 11.6318 17.7015 11.3333 17.3333 11.3333C16.2266 11.3333 15.0649 10.7783 14.1614 9.8851C13.2579 8.99196 12.6863 7.83353 12.6672 6.72441L12.6666 6.65497Z"
          fill="#00338C"
        />
      </g>
      <defs>
        <clipPath id="clip0_6418_2864">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewStatusIcon;
