import React, { FC, useState } from "react";
import { ExtendedStripeSubscriptionPlan } from "api-services/definitions/stripe";
import classNames from "classnames";
import { groupBy, orderBy } from "lodash";

import { useAuth } from "@contexts/auth";
import useFeedbackModal from "@hooks/use-feedback-modal";
import { SubscriptionPlanType } from "@lib/data/schemas/subscription-plan";
import {
  getSavedAmountInPercentage,
  isDowngradeSubscriptionPlan,
  isFrequencyChange,
  subscriptionPlanDescriptionStrings,
} from "@lib/utils/subscription-plans";

import FeedbackModal from "@components/Modals/FeedbackModal";
import Radio from "@components/Radio/Radio";
import Tag from "@components/Tag/Tag";

import SubscriptionPlanCard from "../SubscriptionPlanCard";
import { SelectPlanContentProps } from "../types";

const SelectPlanContent: FC<SelectPlanContentProps> = ({
  theme = "light",
  className,
  currentPlan,
  intervalOptions,
  subscriptionPlans,
  onChange,
  onKeep,
  interval,
  cardPrimaryButtonText,
}) => {
  const { isOwner } = useAuth();
  const [selectedInterval, setSelectedInterval] = useState(
    interval || currentPlan?.frequency || "year"
  );
  const groupedByFrequency = groupBy(
    subscriptionPlans,
    (item) => item.frequency
  );
  const findBasicPlans = (frequency: SubscriptionPlanType["frequency"]) =>
    subscriptionPlans?.find(
      (item) => item.tier === "basic" && item.frequency === frequency
    );
  const discountInPercentage = getSavedAmountInPercentage(
    findBasicPlans("month"),
    findBasicPlans("year")
  );

  const options = intervalOptions.map((item) => ({
    ...item,
    label:
      item.value === "year" && discountInPercentage ? (
        <>
          {item.label}
          <Tag className="ml-2" color={"blue"}>
            -{discountInPercentage}
          </Tag>
        </>
      ) : (
        item.label
      ),
  }));

  const cards = orderBy(groupedByFrequency[selectedInterval], ["tier"]);
  const isCurrentPlan = (item: SubscriptionPlanType) =>
    item.tier === currentPlan?.tier && item.frequency === currentPlan.frequency;

  const [showFeedbackModal, setShowFeedbackModal] = useFeedbackModal();

  const handleChange = (item: SubscriptionPlanType) => () => {
    if (item.frequency === "lifetime") {
      return setShowFeedbackModal(true);
    }
    if (isCurrentPlan(item)) {
      onKeep && onKeep();
    } else {
      onChange(item);
    }
  };

  const getCardPrimaryButtonText = (item: ExtendedStripeSubscriptionPlan) => {
    const updateOrUpgrade =
      currentPlan && isFrequencyChange(currentPlan, item)
        ? "Update"
        : "Upgrade";

    const planText =
      currentPlan && isDowngradeSubscriptionPlan(currentPlan, item)
        ? "Downgrade"
        : updateOrUpgrade;

    const keepOrChangePlan = isCurrentPlan(item) ? "Keep" : planText;

    const hardCodedText = !currentPlan
      ? "Start 7-day free trial"
      : keepOrChangePlan;
    return cardPrimaryButtonText ? cardPrimaryButtonText : hardCodedText;
  };

  return (
    <div className={className}>
      <div className="mb-4">
        <Radio
          className={classNames(
            options.length > 2 ? `sm:max-w-lg` : `sm:max-w-sm`,
            `m-auto`
          )}
          theme={theme}
          value={selectedInterval}
          onChange={setSelectedInterval}
          options={options}
          withCustom={false}
        />
      </div>
      <section
        className={classNames(
          "grid",
          cards?.length === 2 && "gap-2 sm:grid-cols-2 max-w-2xl m-auto",
          cards?.length === 3 && "mt-8 gap-3 sm:grid-cols-3"
        )}
      >
        {cards.map((item, index) => (
          <SubscriptionPlanCard
            key={`subscription-plan-item-${item.frequency}-${index}`}
            className="md:min-h-[447px]"
            title={item.name}
            description={subscriptionPlanDescriptionStrings[item.tier]}
            currency={item.currency}
            frequency={item.frequency}
            price={item.amount}
            onClickPrimaryButton={handleChange(item)}
            showPrimaryButton={isOwner}
            theme={theme}
            variant={item.tier}
            primaryButtonText={getCardPrimaryButtonText(item)}
            isCurrentPlan={isCurrentPlan(item)}
            showDetails
          />
        ))}
        <FeedbackModal
          showModal={showFeedbackModal}
          setShowModal={setShowFeedbackModal}
          title="Subscription support"
          description="Our support team will help upgrade you to the Lifetime Subscription on Practice. We're standing by and happy to help!"
        />
      </section>
    </div>
  );
};

export default SelectPlanContent;
