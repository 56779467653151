import { ExtendedStripeSubscriptionPlan } from "api-services/definitions/stripe";
import last from "lodash/last";
import { Stripe } from "stripe";

import { displayItemAmountString } from "@lib/products";

export const formatPlanPrice = (
  plan: ExtendedStripeSubscriptionPlan,
  discounted = false,
  hideDigits = false,
  asMonthly = true
): string | undefined => {
  const { frequency, currency, defaultCoupon, amount } = plan;
  const discountedAmount = discounted && defaultCoupon?.amountOff;
  const price = discountedAmount ? amount - discountedAmount : amount;
  return displayItemAmountString(
    frequency === "year" && asMonthly
      ? Math.round(price / 12)
      : Math.round(price),
    currency,
    undefined,
    undefined,
    {
      maximumFractionDigits: hideDigits ? 0 : price > 9 ? 0 : 2,
      minimumFractionDigits: 0, // this line prevents a bug on some browsers
    }
  );
};

export const formatPlanPaymentFrequency = (
  plan: ExtendedStripeSubscriptionPlan
): string => {
  if (plan.frequency === "lifetime") return "";

  return plan.frequency === "year" ? "/yr" : "/mo";
};

/**
 * Returns the total of free members seats given a subscription
 *
 * @param subscription - the stripe subscripiton
 * @returns the total of free members seats
 * */
export const getFreeMembersSeats = (
  subscription: Stripe.Subscription | undefined
): number => {
  if (!subscription) return 0;

  // @ts-expect-error - stripe types are not updated, but the plan is there
  const plan = subscription.plan as Stripe.Plan;

  if (!plan) return 0;

  const planTiers = plan?.tiers ?? [];

  const filteredZeroTiers = planTiers.filter((tier) => tier.unit_amount === 0);

  // we set enterprise prices different than the regular members price
  // that is the reason we are looking for the first tier with an up_to value
  const findTotalFree = last(filteredZeroTiers)?.up_to || null;

  // if we don't find a tier with an up_to value, we look for the free tiers
  const totalFree = findTotalFree ?? (filteredZeroTiers?.length || 0);

  return totalFree;
};

/**
 * Returns the price per seat given a subscription
 *
 * @param subscription - the stripe subscripiton
 * @returns the price per seat formated
 * */
export const getPricePerSeat = (
  subscription: Stripe.Subscription | undefined
): string | null => {
  if (!subscription) return null;

  // @ts-expect-error - stripe types are not updated, but the plan is there
  const plan = subscription.plan as Stripe.Plan;

  if (!plan) return null;

  const planTiers = plan?.tiers ?? null;

  if (!planTiers) return null;

  const unitAmount =
    planTiers.find((tier) => tier?.unit_amount && tier.unit_amount > 0)
      ?.unit_amount || null;

  if (!unitAmount) return null;

  return `$${unitAmount / 100}`;
};
