import { FC, ReactNode } from "react";
import classNames from "classnames";
import { Stripe } from "stripe";

import { displayItemAmountString } from "@lib/products";
import { featuresByType } from "@lib/utils/subscription-plans";

import { Button } from "@components/Button";
import BasicBadgeIcon from "@components/Icons/BasicBadgeIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import ProBadgeIcon from "@components/Icons/ProBadgeIcon";
import TeamsBadgeIcon from "@components/Icons/TeamsBadgeIcon";
import Tag from "@components/Tag/Tag";
import { Tooltip, TooltipSize } from "@components/Tooltips/Tooltip";

import {
  StripeSubscriptionType,
  StyleType,
  SubscriptionPlanCardProps,
} from "../types";
import { getFreeMembersSeats } from "../utils";

const styles: StyleType = {
  light: {
    basic: {
      container: "bg-grey-950 text-black-ink",
      description: "text-grey-500",
      price: "text-black-ink",
      priceDescription: "text-action-400",
      checkbox: "bg-grey-500 text-grey-950",
      featureItem: "text-black-ink",
      badge: BasicBadgeIcon,
    },
    pro: {
      container:
        "bg-[radial-gradient(circle_at_bottom_left,_var(--tw-gradient-stops))] from-[#00338C] via-[#061632] to-black-ink",
      description: "text-white",
      priceDescription: "text-grey-500",
      price: "text-white hover:text-white",
      checkbox: "bg-white text-blue-100",
      checkboxHighlighted: "bg-action-700 text-blue-100",
      featureItem: "text-white",
      featureItemHighlighted: "text-action-700",
      badge: ProBadgeIcon,
    },
    teams: {
      container: "bg-gradient-to-br from-grey-950 to-[#EBD2B9]",
      description: "text-grey-500",
      price: "text-black-ink",
      checkbox: "bg-action-500 text-action-950",
      featureItem: "text-action-400",
      badge: TeamsBadgeIcon,
    },
  },
  dark: {
    basic: {
      container: "bg-grey-100 text-white border border-grey-250",
      priceDescription: "text-grey-500",
      checkbox: "bg-grey-900 text-black-ink",
      featureItem: "text-grey-900",
    },
    pro: {
      container:
        "bg-[radial-gradient(circle_at_top,_var(--tw-gradient-stops))] from-[#022E7B] via-[#03122b] to-black-ink text-white",
      priceDescription: "text-grey-500",
      checkbox: "bg-white text-blue-100",
      checkboxHighlighted: "bg-action-700 text-blue-100",
      featureItem: "text-white",
      featureItemHighlighted: "text-action-700",
    },
    teams: {},
  },
};

export const PriceLine: FC<{
  formattedPrice?: string;
  formattedOriginalPrice?: string;
  pre?: ReactNode;
  frequency: string | null;
}> = ({ formattedPrice, pre, frequency, formattedOriginalPrice }) => {
  return (
    <div className="flex justify-between items-center w-full">
      {pre}
      <div
        className={classNames(
          "flex items-baseline leading-tight",
          pre ? "text-2xl" : "text-3xl"
        )}
      >
        {formattedOriginalPrice && (
          <strong className={"font-medium text-grey-600 line-through mr-1.5"}>
            {formattedOriginalPrice}
          </strong>
        )}
        <strong className="font-medium">{formattedPrice}</strong>
        <span className="text-base">{frequency ? `/${frequency}` : null}</span>
      </div>
    </div>
  );
};

const MembersSubscriptionInfo: FC<{ subscription: StripeSubscriptionType }> = ({
  subscription,
}) => {
  if (!subscription) return null;

  const plan = subscription.plan as Stripe.Plan;

  if (!plan) return null;

  const firstItem = subscription.items.data[0];
  const planTiers = plan?.tiers ?? [];

  const totalFree = getFreeMembersSeats(subscription);

  const unitPrice =
    planTiers.find((tier) => tier.unit_amount && tier.unit_amount > 0)
      ?.unit_amount || 0;
  const totalItems = firstItem?.quantity || 0;
  const totalToPay = totalItems - totalFree;

  const price = unitPrice / 100;
  const frequency = firstItem.price?.recurring?.interval || "month";
  const currency = firstItem.price?.currency || "usd";
  const totalPrice = price * totalToPay;

  if (!unitPrice || totalPrice < 1) return null;

  const formattedPrice = displayItemAmountString(
    Math.round(totalPrice),
    currency,
    undefined,
    undefined,
    {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0, // this line prevents a bug on some browsers
    }
  );

  return (
    <PriceLine
      pre={
        <div className="mr-auto">{`Additional members (${totalToPay})`}</div>
      }
      formattedPrice={formattedPrice}
      frequency={frequency}
    />
  );
};

const SubscriptionPlanCard: FC<SubscriptionPlanCardProps> = ({
  theme = "light",
  variant,
  showDetails = false,
  showPrimaryButton = false,
  frequency,
  originalPrice, // only when a promocode is used
  price,
  currency,
  onClickPrimaryButton,
  title,
  description,
  className,
  primaryButtonText: primaryButtonLabel,
  isInFreeTrial = false,
  isCurrentPlan = false,
  showMostPopularBadge = true,
  membersSubscriptionInformation,
}) => {
  const style = styles[theme][variant] || {};
  const BadgeTitle = style?.badge || null;
  const isVariantBasic = variant === "basic";
  const isVariantTeams = variant === "teams";
  const isFrequencyYear = frequency === "year";
  const isLifetimePlan = frequency === "lifetime";
  const isThemeDark = theme === "dark";
  const formatFractionOpts = {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0, // this line prevents a bug on some browsers
  };
  const formattedOriginalPrice =
    (originalPrice &&
      displayItemAmountString(
        originalPrice,
        currency,
        undefined,
        undefined,
        formatFractionOpts
      )) ||
    "";

  const formattedPrice = displayItemAmountString(
    price,
    currency,
    undefined,
    undefined,
    formatFractionOpts
  );

  const formattedPriceByMonth =
    (!isLifetimePlan &&
      displayItemAmountString(
        frequency === "year" ? Math.round(price / 12) : Math.round(price),
        currency,
        undefined,
        undefined,
        formatFractionOpts
      )) ||
    "";

  const getButtonVariant = () => {
    if (isThemeDark) return "darkAction";
    if (isVariantBasic) {
      if (showDetails) return "grey";
      else return "dark";
    }
    if (isVariantTeams) return "primary";

    return "white";
  };

  const renderFeatures = showDetails && (
    <ul className="mb-8 flex-1">
      {featuresByType[variant]?.map(
        ({ icon: Icon = CheckIcon, ...item }, index) => (
          <li
            key={`subscription-plan-feature-item-${variant}-${index}`}
            className={classNames(
              item?.highlighted
                ? style?.featureItemHighlighted
                : style?.featureItem,
              "flex items-center mb-2 text-sm"
            )}
          >
            <span
              className={classNames(
                item?.highlighted
                  ? style?.checkboxHighlighted
                  : style?.checkbox,
                "w-5 h-5 rounded-full mr-2 flex items-center justify-center"
              )}
            >
              <Icon />
            </span>
            {item.value}
            {!!item?.comingSoon && (
              <span className="ml-2 text-white text-xs border border-white rounded px-1.5 py-0.5 opacity-50">
                Coming Soon
              </span>
            )}
          </li>
        )
      )}
    </ul>
  );

  return (
    <div
      className={classNames(
        style?.container,
        showDetails && "first:my-6 last:my-6",
        "inner-border inner-border-black-ink/5 p-6 rounded-lg flex flex-col",
        className
      )}
    >
      <div className="mb-4 flex items-center gap-x-2">
        {isThemeDark && (
          <h2 className="text-3xl font-medium leading-tight">{title}</h2>
        )}
        {BadgeTitle && <BadgeTitle />}
        {isInFreeTrial && (
          <Tag color="white" className="!py-1" isSelected>
            Free trial
          </Tag>
        )}
        {variant === "pro" && showMostPopularBadge && (
          <Tag color="white-transparent" className="!py-1" isSelected>
            Most popular
          </Tag>
        )}
        {isCurrentPlan && (
          <small
            className={classNames(
              "py-1 text-xs font-medium",
              style?.description
            )}
          >
            Current plan
          </small>
        )}
      </div>

      <p
        className={classNames("text-md mb-4 text-grey-500", style?.description)}
      >
        {description}
      </p>
      {!showDetails && (
        <p
          className={classNames(
            "font-medium text-3xl flex items-end",
            style?.price
          )}
        >
          {formattedPrice}
          <span className="font-normal text-xl mb-1 ml-2">/{frequency}</span>
          {formattedOriginalPrice && (
            <Tooltip
              className="ml-1 mb-2"
              trigger={
                <InfoIcon
                  className={classNames(
                    "hover:text-black-ink w-4",
                    style?.price
                  )}
                />
              }
              size={TooltipSize.medium}
            >
              You used a promocode{" "}
              {price === 1 &&
                `and will be charged $1 for your
            first month of Practice at the end of your 7-days free trial`}
            </Tooltip>
          )}
        </p>
      )}

      <div
        className={classNames(
          "flex items-center",
          style?.price,
          (showDetails && (isFrequencyYear || isLifetimePlan)) ||
            membersSubscriptionInformation
            ? "mb-0"
            : "mb-4"
        )}
      >
        {showDetails && (
          <PriceLine
            pre={null}
            formattedOriginalPrice={formattedOriginalPrice}
            formattedPrice={
              showDetails && !isLifetimePlan
                ? formattedPriceByMonth
                : formattedPrice
            }
            frequency={
              !isLifetimePlan ? (showDetails ? "mo" : frequency) : null
            }
          />
        )}
      </div>
      {showDetails && isFrequencyYear && (
        <p className="mb-4 text-grey-500 text-sm">
          {formattedPrice} billed yearly
        </p>
      )}

      {showDetails && isLifetimePlan && (
        <p
          className={classNames(
            style?.priceDescription,
            "mb-4 flex items-center"
          )}
        >
          <span>Lifetime ownership</span>
          <Tooltip
            className="inline-block ml-2"
            trigger={<InfoIcon />}
            size={TooltipSize.medium}
          >
            A one-time payment to Practice for lifetime access to all features.
          </Tooltip>
        </p>
      )}

      {!showDetails && membersSubscriptionInformation && (
        <div className={classNames("mb-4", style?.price)}>
          <MembersSubscriptionInfo
            subscription={membersSubscriptionInformation.subscription}
          />
        </div>
      )}

      {renderFeatures}

      {showPrimaryButton && (
        <>
          {!showDetails && <div className="flex-1" />}
          <Button
            className="w-full"
            variant={getButtonVariant()}
            onClick={onClickPrimaryButton}
          >
            {isLifetimePlan ? "Contact us" : primaryButtonLabel}
          </Button>
        </>
      )}
    </div>
  );
};

export default SubscriptionPlanCard;
