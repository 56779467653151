import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M14.898 2.113a1 1 0 00-1.33.484L11.456 7.13 8.737 5.86a1 1 0 00-1.33.484l-.422.906-.845 1.812-3.381 7.25 4.376 2.042 1.557-.567-.495 1.062 3.865 1.802A1 1 0 0013 22h6a1 1 0 100-2h-4.72l2.735-5.865.846-1.813.422-.906a1 1 0 00-.483-1.329l-2.72-1.268 2.114-4.531a1 1 0 00-.484-1.33l-1.813-.845zM5.417 15.347l2.535-5.438 7.25 3.38-2.535 5.438-1.813-.845 1.691-3.625-5.316 1.935-1.812-.845zM4 21a1 1 0 011-1h2a1 1 0 110 2H5a1 1 0 01-1-1z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default Icon;
