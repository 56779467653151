import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PracticeIcon: FC<SVGIconProps> = ({
  className,
  width = 32,
  height = 32,
}) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      fill="none"
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1780_23082)">
        <path
          d="M28.9538 25.3936C26.0457 29.3972 21.3268 32.0004 15.9999 32.0004C10.6729 32.0004 5.95404 29.3972 3.0459 25.3936L8.87713 22.1281C13.3023 19.65 18.6975 19.65 23.1226 22.1281L28.9538 25.3936Z"
          fill="#EDA154"
        />
        <path
          d="M1.12882 21.9144C0.694747 20.8239 0.377155 19.6744 0.191406 18.4812L12.8576 14.4281C14.9016 13.774 17.0988 13.774 19.1428 14.4281L31.809 18.4813C31.6232 19.6744 31.3056 20.8239 30.8716 21.9144L18.0591 17.8145C16.7199 17.3859 15.2804 17.3859 13.9412 17.8145L1.12882 21.9144Z"
          fill="#F3B36A"
        />
        <path
          d="M31.183 10.9398C31.5778 12.1251 31.8377 13.372 31.9445 14.6624L17.0669 12.282C16.3598 12.1689 15.6393 12.1689 14.9322 12.282L0.0546875 14.6624C0.161468 13.372 0.421374 12.1251 0.816191 10.9398L14.3705 8.7711C15.4497 8.59843 16.5494 8.59843 17.6286 8.7711L31.183 10.9398Z"
          fill="#F9C682"
        />
        <path
          d="M26.0571 3.55566C27.31 4.56941 28.4066 5.76849 29.3055 7.11122H2.69434C3.59316 5.76849 4.68983 4.56941 5.94266 3.55566H26.0571Z"
          fill="#FFDA9A"
        />
      </g>
      <defs>
        <clipPath id="clip0_1780_23082">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PracticeIcon;
