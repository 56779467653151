import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM14.2323 11.6803C14.7367 12.0202 15.1594 12.4719 15.4649 13C15.4767 13.0204 15.4886 13.0414 15.5 13.062C15.742 13.4981 15.9049 13.9843 15.9692 14.501C16.0033 14.775 15.7761 15 15.5 15H14.5C14.311 15 14.1495 14.8935 14.0445 14.7405C14.0163 14.6993 13.9921 14.6546 13.9726 14.6078C13.9614 14.581 13.9518 14.5535 13.9438 14.5255C13.9419 14.5187 13.94 14.5119 13.9383 14.5051C13.8348 14.0985 13.6067 13.7417 13.2971 13.4776C12.9568 13.1876 12.5181 13.0095 12.0387 13.0004C12.0258 13.0001 12.0129 13 12 13C11.5051 13 11.0521 13.1798 10.7029 13.4776C10.3933 13.7417 10.1652 14.0985 10.0617 14.5051C10.06 14.5119 10.0581 14.5187 10.0562 14.5255C10.0482 14.5535 10.0386 14.581 10.0274 14.6078C10.0079 14.6546 9.98374 14.6993 9.95545 14.7405C9.8505 14.8935 9.689 15 9.5 15H8.5C8.22386 15 7.99672 14.775 8.03082 14.501C8.09513 13.9843 8.25799 13.4981 8.5 13.062C8.51144 13.0414 8.52307 13.0209 8.53486 13.0005C8.84033 12.4724 9.26333 12.0202 9.76771 11.6803C10.3917 11.2597 11.141 11.0104 11.9488 11.0003C11.9658 11.0001 11.9829 11 12 11C12.0171 11 12.0342 11.0001 12.0512 11.0003C12.859 11.0104 13.6083 11.2597 14.2323 11.6803ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
      />
    </svg>
  );
};

export default Icon;
