import React, { FC, useState } from "react";
import { getWebflowVideo } from "api-services/definitions/webflow";
import { useApiGet } from "api-services/endpoints";
import classNames from "classnames";
import capitalize from "lodash/capitalize";

import { useIsMobile } from "@hooks/use-window-size";
import { videoIds } from "@lib/constants/webflow";

import SmallBanner from "@components/Banner/SmallBanner";
import { Button } from "@components/Button";
import AssignIcon from "@components/Icons/AssignIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import GearSimpleIcon from "@components/Icons/GearSimpleIcon";
import PaletteIcon from "@components/Icons/PaletteIcon";
import PenIcon from "@components/Icons/PenIcon";
import PlayIcon from "@components/Icons/PlayIcon";
import PracticeIcon from "@components/Icons/PracticeIcon";
import ProBadgeIcon from "@components/Icons/ProBadgeIcon";
import SocialWebIcon from "@components/Icons/SocialWebIcon";
import TeamsBadgeIcon from "@components/Icons/TeamsBadgeIcon";
import ZapierIcon from "@components/Icons/ZapierIcon";
import Modal from "@components/Modals/Modal";
import VideoModal from "@components/Video/VideoModal/VideoModal";

export interface TiersWelcomeModalProps {
  onDismiss: () => void;
  variant: "pro" | "teams";
}

const data = {
  pro: {
    badge: ProBadgeIcon,
    description:
      "Practice Pro is the most professional coaching experience for your clients.",
    items: [
      {
        Icon: PaletteIcon,
        text: "Add your look and feel with branded client portal",
      },
      {
        Icon: PenIcon,
        text: "Advanced contracts & digital signatures",
      },
      {
        Icon: SocialWebIcon,
        text: "Custom domain & White label",
      },
      {
        Icon: ZapierIcon,
        text: "Zapier integration",
      },
    ],
  },
  teams: {
    badge: TeamsBadgeIcon,
    description:
      "Grow, manage and scale your team operations on Practice. Run all of your client engagements under one roof.",
    items: [
      {
        Icon: AssignIcon,
        text: "Assign permissions and add team members to your account",
      },
      {
        Icon: GearSimpleIcon,
        text: "Personal and team integrations",
      },
      {
        Icon: CheckIcon,
        text: "Assign and manage tasks amongst your team",
      },
    ],
  },
};

const TiersWelcomeModal: FC<TiersWelcomeModalProps> = ({
  onDismiss,
  variant,
}) => {
  const { badge: BadgeTier, description, items } = data[variant] ?? {};
  const isMobile = useIsMobile();

  const [showVideoModal, setShowVideoModal] = useState(false);

  const { data: video } = useApiGet(getWebflowVideo, {
    videoId: videoIds[`${variant}Modal`],
  });

  const renderCTA = () => (
    <Button variant="primary" onClick={onDismiss}>
      Let’s go!
    </Button>
  );

  const renderContent = () => (
    <>
      <div className="self-start bg-action-950 p-3 rounded-full">
        <PracticeIcon />
      </div>
      <h1 className="flex items-center gap-2 text-2xl leading-tight text-black-ink">
        Welcome to <BadgeTier />
      </h1>
      <p className="text-md text-grey-500">{description}</p>
      <SmallBanner className="mb-auto" items={items} />
    </>
  );

  const renderVideo = () => (
    <div
      className={classNames(
        "flex flex-1 h-[196px] sm:h-auto sm:min-h-[500px] bg-cover bg-top bg-no-repeat rounded-2xl overflow-hidden group hover:cursor-pointer",
        variant === "teams" && "bg-[url('/images/img_teams_video@3x.png')]",
        variant === "pro" && "bg-[url('/images/img_pro_video@3x.png')]"
      )}
      onClick={() => setShowVideoModal(true)}
      aria-label="Watch Practice intro video"
    >
      <div className="w-full h-full flex justify-center items-center group-hover:bg-black-ink/10">
        <div className="flex self-center items-center gap-2 p-4 rounded-full bg-grey-100 group-hover:bg-black-ink text-white group-hover:text-action-700">
          <PlayIcon />
          <span className="font-medium">
            About {capitalize(variant)} (2 min)
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        className="overflow-y-auto"
        size={isMobile ? "full" : "bigger"}
        show={!showVideoModal}
        toggleShow={onDismiss}
        disableClose
      >
        <div className="flex flex-1 flex-col sm:flex-row gap-6 p-8">
          <div className="flex flex-1 flex-col sm:basis-5/12 gap-4 mb-auto sm:mb-0">
            {renderContent()}
            {!isMobile && renderCTA()}
          </div>

          <div className="flex flex-1 sm:basis-7/12">{renderVideo()}</div>
          {isMobile && renderCTA()}
        </div>
      </Modal>

      {showVideoModal && (
        <VideoModal
          show
          toggleShow={() => setShowVideoModal(false)}
          videoUrl={video?.youtubeUrl}
          autoplay
        />
      )}
    </>
  );
};

export default TiersWelcomeModal;
