import React, { ChangeEvent, ReactNode, useState } from "react";
import axios from "axios";
import { compact, isEmpty } from "lodash";

import { useAuth } from "@contexts/auth";

import { Button } from "@components/Button";
import LargeTextAreaForm from "@components/Form/LargeTextAreaForm";
import HeartIcon from "@components/Icons/HeartIcon";
import { InlineUpload, UploadFileInfo } from "@components/UploadModal";

import Modal from "./Modal";

interface FeedbackModalProps {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  supportModal?: boolean;
  defaultFeedback?: string;
  title?: string;
  description?: string;
  messageInputLabel?: string;
  confirmationTitle?: string;
  confirmationDescription?: string;
  confirmationIcon?: ReactNode;
  onSuccess?: () => void;
  onClickConfirmationClose?: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  showModal,
  setShowModal,
  supportModal,
  defaultFeedback = "",
  title,
  description,
  messageInputLabel,
  confirmationTitle,
  confirmationDescription,
  confirmationIcon,
  onSuccess,
  onClickConfirmationClose,
}) => {
  const { aid } = useAuth();
  const [feedback, setFeedback] = useState(defaultFeedback);
  const [showThanksScreen, setShowThanksScreen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [files, setFiles] = useState<UploadFileInfo[]>([]);
  const allFiles = compact(files?.map((item) => item?.uploadedFile?.url));

  const onSubmitFeedback = async () => {
    setIsSubmitting(true);
    await axios.post("/api/v1/feedback", {
      userId: aid,
      content: allFiles.length
        ? `${feedback} \n ${allFiles.join("\n")}`
        : feedback,
    });

    setShowThanksScreen(true);
    setIsSubmitting(false);
    setFeedback("");
    setFiles([]);
    onSuccess && onSuccess();
  };
  // To ensure that when closing modal by clicking backdrop, the feedback modal
  // goes back to step 1 (the text field)
  const toggleModal = (val: boolean) => {
    setShowModal(val);
    setFiles([]);
    setTimeout(() => setShowThanksScreen(false), 500);
  };

  const handleClose = () => {
    toggleModal(false);
    onClickConfirmationClose && onClickConfirmationClose();
  };

  return (
    <Modal
      show={showModal}
      toggleShow={toggleModal}
      className="p-6"
      size="small"
    >
      {showThanksScreen ? (
        <div className="flex flex-col items-center">
          {confirmationIcon ? (
            confirmationIcon
          ) : (
            <HeartIcon className="w-16 h-16 mb-3 mt-12" />
          )}
          <h1 className="text-3xl mb-2">{confirmationTitle ?? "Thank you"}</h1>
          <p className="leading-6 text-center text-lg text-gray-800 mb-8">
            {confirmationDescription
              ? confirmationDescription
              : supportModal
              ? "We appreciate you taking the time to request support. We'll respond as soon as possible."
              : "We appreciate you taking the time to provide feedback. What you shared will help us improve the product."}
          </p>
          <Button className="w-full" onClick={handleClose}>
            Close
          </Button>
        </div>
      ) : (
        <>
          <div className="flex justify-between mb-7 items-center">
            <h1 className="text-3xl">
              {title
                ? title
                : supportModal
                ? "Request support"
                : "Send feedback"}
            </h1>
            <Button
              primary
              onClick={onSubmitFeedback}
              disabled={isEmpty(feedback) || isSubmitting}
              isLoading={isSubmitting}
            >
              Send
            </Button>
          </div>
          <p className="text-lg text-gray-800 mb-4">
            {description
              ? description
              : supportModal
              ? "Need support with a problem or have questions about features? We're standing by and happy to help."
              : "We would like to know how you feel about our app. Do you have any issues or suggestions?"}
          </p>
          <label className="text-grey-500 text-sm mb-2">
            {messageInputLabel
              ? messageInputLabel
              : supportModal
              ? "Private support"
              : "Private feedback"}
          </label>
          <LargeTextAreaForm
            placeholder="Start typing..."
            content={feedback}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setFeedback(e.target.value)
            }
          />
          <br />
          <InlineUpload
            vertical
            files={files}
            setFiles={setFiles}
            uploadPath={`/users/${aid}/feedback/`}
            multiple={true}
            variant="light"
          />
        </>
      )}
    </Modal>
  );
};

export default FeedbackModal;
